<script>
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/pt-br";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ProcedureService from "@/services/procedure-service";
import PatientService from "@/services/patient-service";
import ProfessionalService from "@/services/professional-service";
import RoomService from "@/services/room-service";
import PaymentTypeService from "@/services/paymenttype-service";
/**
 * Procedures Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      pacientes: {},
      title: "Procedures",
      items: [
        {
          text: "Atendimentos",
        },
        {
          text: "Procedures",
          active: true,
        },
      ],
      proceduresData: [],
      history: {},
      submitted: false,
      showmodal: false,
      showmodalhistory: false,
      //Paginacao
      pagination: {
        numberOfElements: 0,
        totalPages: 0,
        totalElements: 0,
        last: true,
        first: true,
        number: 0,
        size: 10,
      },
      paginate: false,
      params: {
        size: 100,
        unitIds: "1,2,3,4,5",
        showOnlyScheduled: false,
      },
      units: [ 3, 5],
      DPDateTime: new Date(new Date().setHours(7, 0, 0, 0)),

      //Modal 
      paymentStatus: ["OPEN", "PAIED", "BILLED", "CANCELED", "GLOSSED", "RECURSED"],
      statusList: ["SCHEDULED", "STARTED", "FINISHED", "CANCELED", "MISSED", "DELETED"],
      procedure: {},
      resultPatientIsOpen: false,
      inputFindPatient: "",
      resultsPatientFind: [],
      patient: {},

      resultProfessionalIsOpen: false,
      inputFindProfessional: "",
      resultsProfessionalFind: [],
      professional: {},
      rooms: [],
      paymentTypes: [],


      filters: {
        dateStart: new Date(new Date().setHours(0, 0, 0, 0)),
        professionalFilter: "",
        patientFilter: "",

      },
    };
  },

  validations: {
    procedure: {
      patient: { required },
      professional: { required },
      room: { required },
    },
  },
  created() {
    this.resetProcedure();
    this.service = new ProcedureService(this.$http);
    this.patientService = new PatientService(this.$http);
    this.professionalService = new ProfessionalService(this.$http);
    this.roomService = new RoomService(this.$http);
    this.paymentTypeService = new PaymentTypeService(this.$http);
    this.chargeRooms();
    this.chargeItens();
    this.chargePaymentTypes();


  },
  methods: {
    chargePaymentTypes() {
      console.log("chargePaymentTypes")
      this.paymentTypeService.list({ size: 100 }).then((res) => {

        console.log("listPaymenttypes", res);
        this.paymentTypes = res.data.content;
        console.log(this.paymentTypes);
      }),
        (err) => {
          console.error(err);
        };
    },
    chargeRooms() {
      this.roomService.list({ size: 100 }).then((res) => {
        console.log(res);
        this.rooms = res.data.content;
      }),
        (err) => {
          console.error(err);
        };
    },
    async chargeItens() {
      try{
        this.loading = true;
        this.params.dateStart = this.filters.dateStart;
        const res = await this.service.list(this.params);
        console.log(res);
        const { content, totalPages, totalElements, last, first, number, size, numberOfElements } = res.data;

        this.proceduresData = content;
        this.proceduresData.forEach(item => {
          item.newRoomSelect = item.room;
        })
        
        Object.assign(this.pagination, { totalPages, totalElements, last, first, number, size, numberOfElements });

        this.paginate = totalPages > 0;

      }catch(err){
        console.error(err);

      }finally{
        this.loading = false;
      }
    },
    changeSize() {
      this.params.page = 0;
      this.chargeItens();
    },
    gotoPage(pageNum) {
      this.params.page = pageNum;
      if (this.search && this.search.length > 2) {
        this.find();
      } else {
        this.chargeItens();
      }
    },
    edit(procedureItem) {
      const procedure = Object.assign({}, procedureItem);

      let date = new Date(Date.parse(procedure.dateTimeStart));
      procedure.dateTimeStart = date;
      this.inputFindPatient = procedure.patient.name;
      this.inputFindProfessional = procedure.professional.name + procedure.professional.activity;
      this.procedure = procedure;
      this.showmodal = true;
    },
    showhistory(procedureItem) {
      this.history = {};
      this.showmodalhistory = true;
      let idhistory = procedureItem.id
      this.gethistory(idhistory)
    },
    gethistory(id) {
      this.service
        .history(id)
        .then((ret) => {
          console.log(ret);
          this.history = ret.data
          this.$toast.success("Historico Carregado");

        });
    },
    canceled(item) {
      item.status = "CANCELED"
      this.save(item)
    },
    confirmed(item) {
      item.status = "FINISHED"
      this.save(item)
    },
    missed(item) {
      item.status = "MISSED"
      this.save(item)
    },
    changeRoom(item, newRoom){
      item.room = newRoom
      this.save(item)
    },
    deleted(item) {
      item.status = "DELETED"
      this.save(item, "Agendamento Removido com Sucesso")
    },
    save(item, message) {
      this.service
        .save(item)
        .then((ret) => {
          console.log(ret);
          if (message) {
            this.$toast.success(message);
          } else {
            this.$toast.success("Salvo com sucesso!");
          }
          this.chargeItens();
        });
    },
    handleItem() {
      const id = this.procedure.id;
      const patient = this.procedure.patient;
      const professional = this.procedure.professional;
      const room = this.procedure.room;
      const dateTimeStart = this.procedure.dateTimeStart;
      const status = this.procedure.status;
      const paymentStatus = this.procedure.paymentStatus;
      const paymentType = this.procedure.paymentType;

      var item = {
        id,
        patient,
        professional,
        room,
        dateTimeStart,
        status,
        paymentStatus,
        paymentType
      };

      return item;
    },

    resetProcedure() {
      this.DPDateTime = new Date(new Date().setHours(7, 0, 0, 0)),
        this.procedure = {
          status: "SCHEDULED",
          paymentStatus: "OPEN",

          dateTimeStart: this.DPDateTime
        }
      this.inputFindPatient = "";
      this.inputFindProfessional = "";
    },
    validateForm() {
      if (this.$v.$invalid) {
        

        this.submitted = false;
        this.$toast.error("Dados invalidos, verifique e tente novamente");
        return false;
      }
      return true;
    }
    ,
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (!this.validateForm()) {

        return
      } else {
        this.save(this.handleItem())
        this.showmodal = false;
        this.resetProcedure()
      }
      this.submitted = false;
      this.resetProcedure();
    },
    saveAndRepeat() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (!this.validateForm()) {
        return
      } else {
        this.save(this.handleItem())

      }
      this.submitted = false;
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.resetProcedure();
    },

    /**
     * Filter the data of search
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tstmpToDate(tstmp) {
      return new Date(tstmp).toLocaleString();
    },
    /**
     *  Busca Paciente
     */
    onChangePatientFind(e) {
      this.resultPatientIsOpen = true;
      if (e.srcElement.value.length < 3) {
        this.procedure.patient = null;
        clearTimeout(this.lastcall);
        this.lastcall = setTimeout(() => {
          this.filterResultsPatient();
        }, 1000);
      } else {
        this.lastcall;
        this.filterResultsPatient();
      }
    },
    filterResultsPatient() {
      console.log("filter ");
      this.procedure.patient = null;
      this.procedure.paymentType = null;
      this.patientService.listByName(this.inputFindPatient).then((ret) => {
        this.resultsPatientFind = ret.data.content;
      });
    },
    setResultPatient(result) {
      this.procedure.patient = result;
      this.procedure.paymentType = this.procedure.patient.paymentType
      this.inputFindPatient = this.procedure.patient.name;

      this.resultPatientIsOpen = false;
      this.$v.patient.$touch()
    },
    /**
     *  Busca Professional
     */
    onChangeProfessionalFind(e) {
      console.log(e);
      this.resultProfessionalIsOpen = true;

      if (e.srcElement.value.length < 3) {
        this.procedure.professional = null;
        clearTimeout(this.lastcall);
        this.lastcall = setTimeout(() => {
          this.filterResultsProfessional();
        }, 1000);
      } else {
        this.lastcall;
        this.filterResultsProfessional();
      }
    },
    filterResultsProfessional() {
      console.log("filter ");
      this.procedure.professional = null;
      this.professionalService
        .listByName(this.inputFindProfessional)
        .then((ret) => {
          this.resultsProfessionalFind = ret.data.content;
        });
    },
    setResultProfessional(result) {
      this.procedure.professional = result;
      this.inputFindProfessional = this.procedure.professional.name + this.procedure.professional.activity;
      this.resultProfessionalIsOpen = false;
      this.$v.professional.$touch()
    },

    //DatePiker
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeXDays(date) {
      let xdays = new Date(new Date().setHours(0, 0, 0, 0));
      console.log(xdays)
      xdays.setDate(xdays.getDate() - 150);
      return date < xdays;
    },
    filtrar() {
      this.params.unitIds = this.units.map(Number).join(",")
      this.params.patientName = this.filters.patientFilter;
      this.params.professionalName = this.filters.professionalFilter;
      this.params.dateStart = this.filters.dateStart
      try {
        this.filters.dateEnd.setHours(23, 59, 59)
      } catch {
        console.log(this.filters.dateEnd)
      }
      this.params.paymentStatus
      this.params.dateEnd = this.filters.dateEnd
      this.params.page = 0
      this.chargeItens();
    },
    showPaymentType(paymentType) {
      if (paymentType) {
        return paymentType.name + " - (" + paymentType.type + ")"
      } else {
        return "";
      }
    }
  },
  computed: {
    roomsFiltred: function () {
      return this.rooms.filter(x => this.units.map(Number).indexOf(x.unit.id) >= 0)

    }

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading" class="d-flex justify-content-center">
      <b-spinner variant="primary"></b-spinner>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" role="tab">
                <h6 class="m-0">
                  <a v-b-toggle.accordion-3 class="text-dark" href="javascript: void(0);">{{ $t("all.filters") }} <i
                      class="mdi mdi-filter-menu mr-2"></i></a>
                </h6>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel" visible>
                <b-card-body class="row">
                  <div class="form-group col-md-6">
                    <label for="name" class="col-md-4">Data Inicio</label>

                    <date-picker v-model="filters.dateStart" format="DD/MM/YYYY HH:mm" value-type="date" type="date"
                      placeholder="DD/MM/YYYY HH:mm" class="col-md-8"></date-picker>

                  </div>
                  <div class="form-group col-md-6">
                    <label for="name" class="col-md-5">Data Final</label>

                    <date-picker v-model="filters.dateEnd" format="DD/MM/YYYY HH:mm" value-type="date" type="date"
                      class="col-md-7" placeholder="DD/MM/YYYY HH:mm"></date-picker>

                  </div>
                  <div class="form-group  col-md-6">
                    <label for="name " class="col-md-5">Paciente</label>
                    <input id="name" v-model="filters.patientFilter" type="text" class=" col-md-7"
                      placeholder="Digite o nome" />
                  </div>
                  <div class="form-group  col-md-6">
                    <label for="name" class="col-md-5">Profissional</label>
                    <input id="name" v-model="filters.professionalFilter" type="text" class="col-md-7"
                      placeholder="Digite o nome" />

                  </div>
                  <!-- <div class="form-group row col-md-3">
                    <label for="name" class="col-md-6">Alpes</label>
                    <b-checkbox v-model="units" value=1 class="col-md-2" />
                  </div>
                  <div class="form-group row col-md-3">
                    <label for="name" class="col-md-6">Almerim</label>
                    <b-checkbox v-model="units" value=2 class="col-md-2" />
                  </div> -->
                  <div class="form-group row col-md-3">
                    <label for="name" class="text-right col-md-6">SCS</label>
                    <b-checkbox v-model="units" value=3 class="col-md-2" />
                  </div>
                  <div class="form-group row col-md-3">
                    <label for="name" class="text-right col-md-6">Caneleiras</label>
                    <b-checkbox v-model="units" value=5 class="col-md-2" />
                  </div>
                  <div class="form-group row col-md-4">
                    <label for="name" class="text-right col-md-6">Mostrar apenas Agendados</label>
                    <b-checkbox v-model="params.showOnlyScheduled" class="col-md-2" />
                  </div>
                  <div class="form-group row col-md-4">
                    <label for="name" class="text-right col-md-6">Mostrar Apagados</label>
                    <b-checkbox v-model="params.showDeleted" class="col-md-2" />
                  </div>
                  <div class="text-right  col-md-8">
                    <b-button type="button" @click="filtrar" variant="success" class="">Filtrar</b-button>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Adicionar Novo
              </a>
            </div>
            <div class="table-responsive mt-3">
              <table class="table table-centered datatable dt-responsive nowrap" style="
                  border-collapse: collapse;
                  border-spacing: 0;
                  width: 100%;
                ">
                <thead class="thead-light">
                  <tr>
                    <th>Data e Hora</th>
                    <th>Paciente</th>
                    <th>Profissional</th>
                    <th>Sala</th>
                    <th>Status</th>
                    <th >Ações</th>
                    <th>Hist.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in proceduresData" :key="index">
                    <td>{{ item.dateTimeStart | formatDateTime }}</td>
                    <td>{{ item.patient.name }}</td>
                    <td>{{ item.professional.name }} - {{ item.professional.activity }}</td>
                    <td>{{ item.room.unit ? item.room.unit.name : "" }} - {{ item.room.name }}</td>

                    <td class="text-nowrap"
                      :class="item.status === 'CANCELED' ? 'table-danger' : item.status === 'FINISHED' ? 'table-success' : item.status === 'DELETED' ? 'table-dark' : 'table-info'">
                      <button v-if="item.status === 'SCHEDULED'" type="button" @click="canceled(item)"
                        class="btn btn-danger"><i class="ri-close-line align-middle"></i></button>
                      <button v-if="item.status === 'SCHEDULED'" type="button" @click="missed(item)"
                        class="btn btn-warning"><i class=" ri-user-unfollow-line align-middle"></i></button>
                      {{ $t("all." + item.status.toLowerCase()) }}
                      <button v-if="item.status === 'SCHEDULED'" type="button" @click="confirmed(item)"
                        class="btn btn-success"><i class="ri-check-line align-middle"></i></button>
                      <button v-if="item.status === 'SCHEDULED'" type="button" @click="deleted(item)" class="btn btn-dark"><i
                          class="ri-delete-bin-line align-middle"></i></button>
                        </td>
                      <td class="text-nowrap">                  
                      <select v-if="item.status === 'SCHEDULED' || item.status === 'FINISHED'"  class="col-md-4 custom-select"  
                      v-model="item.newRoomSelect"  >
                            
                            <option selected :value="item.room">{{ item.room.name }} - {{ item.room.unit ? item.room.unit.name : "" }}</option>
                            <option v-for="(room, i) in roomsFiltred" :key="i" :value="room" >
                                 {{ room.name }} - {{ room.unit ? room.unit.name : "" }}
                              </option>
                            </select>    
                      <button v-if="item.status === 'SCHEDULED' || item.status === 'FINISHED'" type="button" @click="changeRoom(item, item.newRoomSelect)"
                        class="btn btn-info"><i class=" ri-swap-box-line align-middle"></i></button>
                      
                        <a href="javascript:void(0);" class="mr-3 text-primary" @click="edit(item)" v-b-tooltip.hover
                        title="Edit">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                    </td>
                    <td>
                      
                      <a href="javascript:void(0);" class="mr-3 text-primary" @click="showhistory(item)" v-b-tooltip.hover
                        title="Histórico">
                        <i class="mdi mdi-history font-size-18"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-card-footer v-if="paginate" class="dataTables_wrapper dt-bootstrap4 no-footer">
                <div class="row">
                  <div class="col-md-11">
                    <ul class="pagination justify-content-left">
                      <li class="page-item">
                        <a v-if="!pagination.first" type="button" class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number - 1)">Anterior</a>
                      </li>
                      <li class="page-item">
                        <a v-for="num in pagination.totalPages" :key="num"
                          :class="num - 1 === pagination.number ? 'active' : ''" class="page-link btn-dark waves-effect"
                          @click="gotoPage(num - 1)">{{ num }}</a>
                      </li>
                      <li class="page-item">
                        <a v-if="!pagination.last" type="button" class="page-link btn-dark waves-effect waves-light"
                          @click="gotoPage(pagination.number + 1)">Próxima</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-1 justify-content-left">
                    <select v-model="params.size" class="form-control" @change="changeSize">
                      <option class="selected" selected>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                </div>
              </b-card-footer>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal id="modal-1" v-model="showmodal" :title="$t('all.procedure')" title-class="text-dark font-18" hide-footer>

      <form @submit.prevent="handleSubmit">
        <!-- Patient INI -->
        <div class="form-group row">
          <label class="col-md-3" for="patient">Paciente</label>
          <div class="col-md-9 input-group mb-3">
            <div class="input-group-prepend">
              <button type="button" class="btn" :class="{
                'btn-success': procedure.patient,
                'btn-danger': !procedure.patient,
              }">
                <span v-if="procedure.patient">OK</span>
                <span v-else>Procurar </span>
              </button>
            </div>
            <input id="patient" v-model="inputFindPatient" type="text" class="form-control" placeholder="Enter name"
              autocomplete="off" @keyup="onChangePatientFind" />
          </div>
          <ul v-if="resultPatientIsOpen" class="autocomplete-resultsPatientFind">
            <li v-for="(result, i) in resultsPatientFind" :key="i" class="autocomplete-result"
              @click="setResultPatient(result)">
              {{ result.name }}
            </li>
          </ul>
        </div>
        <!-- Patient END-->
        <!-- Professional INI -->
        <div class="form-group row">
          <label class="col-md-3" for="professional">Profissional</label>
          <div class="col-md-9 input-group ">
            <div class="input-group-prepend">
              <button type="button" class="btn" :class="{
                'btn-success': procedure.professional,
                'btn-danger': !procedure.professional,
              }">
                <span v-if="procedure.professional">OK</span>
                <span v-else>Procurar </span>
              </button>
            </div>
            <input id="professional" v-model="inputFindProfessional" type="text" class="form-control"
              placeholder="Enter name" autocomplete="off" @keyup="onChangeProfessionalFind" />
          </div>
          <ul v-if="resultProfessionalIsOpen" class="autocomplete-results">
            <li v-for="(result, i) in resultsProfessionalFind" :key="i" class="autocomplete-result"
              @click="setResultProfessional(result)">
              {{ result.name }} - {{ result.activity  }}
            </li>
          </ul>
        </div>
        <!-- Professional END-->
        <div class="form-group row">
          <label class="col-md-4 col-form-label"> Sala </label>

          <select class="col-md-8 custom-select" v-model="procedure.room"
            :class="{ 'is-invalid': submitted && $v.procedure.room.$error }">
            <option selected="selected" disabled>Selecione a Sala</option>

            <option v-for="(room, i) in roomsFiltred" :key="i" :value="room">
              {{ room.unit ? room.unit.name : "" }} {{ room.name }}
            </option>
          </select>
          <div v-if="submitted && $v.procedure.room.$error" class="invalid-feedback">
            <span v-if="!$v.procedure.room.required">Sala é requerida</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"> Status </label>

          <select class="col-md-8 custom-select" v-model="procedure.status">
            <option v-for="(status, i) in statusList" :key="i" :value="status">
              {{ $t("all." + status.toLowerCase()) }}
            </option>
          </select>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"> {{ $t("all.paymentType") }} </label>

          <select class="col-md-8 custom-select" v-model="procedure.paymentType">
            <option :value="procedure.paymentType">{{ showPaymentType(procedure.paymentType) }}</option>
            <option v-for="(paymentType, i) in paymentTypes" :key="i" :value="paymentType" selected>
              {{ showPaymentType(paymentType) }}
            </option>
          </select>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label"> {{ $t("all.paymentStatus") }} </label>

          <select class="col-md-8 custom-select" v-model="procedure.paymentStatus">
            <option :value="procedure.paymentStatus" selected>{{ procedure.paymentStatus ? $t("all." +
              procedure.paymentStatus.toLowerCase()) : "" }}</option>
            <option v-for="(paymentStatusitem, i) in paymentStatus" :key="i" :value="paymentStatusitem">
              {{ $t("all." + paymentStatusitem.toLowerCase()) }}
            </option>
          </select>
        </div>

        <div class="form-group row">
          <label class="col-md-4 col-form-label"> Data e Hora </label>

          <date-picker class="col-md-6" v-model="procedure.dateTimeStart" format="DD/MM/YYYY HH:mm" value-type="date"
            type="datetime" placeholder="DD/MM/YYYY HH:mm" :timePickerOptions="{
              start: '07:00',
              step: '00:30,',
              end: '22:00',
            }" :disabled-date="notBeforeXDays"></date-picker>

        </div>

        <div class="text-right">
          <b-button class="mr-1" variant="warning" @click="saveAndRepeat">
            {{ $t("all.saveandrepeat") }}
          </b-button>
          <button type="submit" class="btn btn-success">
            {{ $t("all.save") }}
          </button>
          <b-button class="ml-1" variant="danger" @click="hideModal">{{
            $t("all.cancel")
          }}</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
    <!-- Modal history-->
    <b-modal v-b-modal.modal-xl size="xl" v-model="showmodalhistory" :title='$t("all.history")'
      title-class="text-dark font-13" hide-footer>
      <span v-for="(hist, indexh) in history" :key="indexh">
        {{ hist[2] }} por: {{ hist[1].username }} em: {{ tstmpToDate(hist[1].revtstmp) }} <br>
        <table border="1" width="100%">
          <tr>
            <td>paciente</td>
            <td>profissional</td>
            <td>status</td>
            <td>sala</td>
            <td>horario</td>
            <td>Pagamento</td>
            <td>Tipo Pagto</td>
          </tr>
          <tr>
            <td>{{ hist[0].patient.name }}</td>
            <td>{{ hist[0].professional.name }}</td>
            <td>{{ hist[0].status }}</td>
            <td>{{ hist[0].room }}</td>
            <td>{{ hist[0].dateTimeStart }}</td>
            <td>{{ hist[0].paymentType ? hist[0].paymentType.name : "" }}</td>
            <td>{{ hist[0].paymentStatus }}</td>
          </tr>
        </table>


      </span>




    </b-modal>
    <!-- end modal history-->
  </Layout>
</template>
